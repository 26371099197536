import {FormField} from "../input";
import {DebitSettingsGroup, SettingsGroup} from "../settingsGroup";
import React from "react";
import {Toggle} from "../toggle";
import {FlowhubDebitSettings} from "./FlowhubDebitSettings";
import {DebitSelect} from "../singleSelect";

export const DebitSettings = ({state, setState, qboAccNames}) => {
    if (state.integration_name === 'CANIX') return
    if (state.integration_name === 'FLOWHUB') return <FlowhubDebitSettings state={state} setState={setState} qboAccNames={qboAccNames}/>
    return (
        <SettingsGroup groupName={<div id='DEBIT'>Debit Payments</div>}
                       reportType='DEBIT'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable module. Creates journal entries that hold non-cash payments summary. Payment methods: default, checkPaid, creditPaid. Granularity: by register, day, payment method.'>
            <Toggle toggleName='Lite mode (Single register)'
                    state={state}
                    setState={setState}
                    name='debit_single_register'
                    tooltipContent='Consolidates by register journals into a single one for a day. Will be created under parent account name'/>
            <FormField groupName='Debit Tracking Class'
                       formName='Tracking class for debit transactions'
                       placeholder='Default value: Disabled'
                       state={state}
                       setState={setState}
                       name='debit_tracking_class'/>
            <DebitSettingsGroup groupName='Default Debit Payment Method'
                                name='debit_payment_types'
                                reportType='debitPaid'
                                state={state}
                                setState={setState}>
                <DebitSelect groupName='Basic Debit Payment Method'
                              formName='Clearing account name'
                              name='debitPaid'
                              state={state}
                              setState={setState}
                              options={qboAccNames}/>
            </DebitSettingsGroup>
            <DebitSettingsGroup groupName='Additional Debit Payment Method (checkPaid)'
                                name='debit_payment_types'
                                reportType='checkPaid'
                                state={state}
                                setState={setState}>
                <DebitSelect groupName='Additional Debit Payments Method (checkPaid)'
                             formName='Clearing account name'
                             name='checkPaid'
                             state={state}
                             setState={setState}
                             options={qboAccNames}/>
            </DebitSettingsGroup>
            <DebitSettingsGroup groupName='Additional Debit Payment Method (prePaymentAmount)'
                                name='debit_payment_types'
                                reportType='prePaymentAmount'
                                state={state}
                                setState={setState}>
                <DebitSelect groupName='Additional Debit Payments Method (prePaymentAmount)'
                             formName='Clearing account name'
                             name='prePaymentAmount'
                             state={state}
                             setState={setState}
                             options={qboAccNames}/>
            </DebitSettingsGroup>
            <DebitSettingsGroup groupName='Additional Debit Payment Method (creditPaid)'
                                name='debit_payment_types'
                                reportType='creditPaid'
                                setState={setState}
                                state={state}>
                <DebitSelect groupName='Additional Debit Payments Method (creditPaid)'
                             formName='Clearing account name'
                             name='creditPaid'
                             state={state}
                             setState={setState}
                             options={qboAccNames}/>
            </DebitSettingsGroup>
        </SettingsGroup>
    )
}