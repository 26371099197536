import {
    Container
} from 'reactstrap';

import React from 'react';

const Terms = () => {
    return (
        <Container className="baseTextBlock" fluid>
            <div>
                <h2>Terms and Conditions</h2>
                <p>
                    Terms and conditions for Palomino Consulting apps and services. By using the Palomino Consulting website ("Service"), all services of Palomino Consulting, Inc. ("Palomino Consulting"), you are agreeing to be bound by the following terms and conditions ("Terms of Service"). If you are using the Service or related services on behalf of a company or other entity, then “Customer” or “you” means that entity, and you are binding that entity to this Agreement palomino.consulting reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes. The use by you of any content or services accessible through the Service may be subject to your acceptance of separate agreements with Palomino Consulting or third parties palomino.consulting places the utmost importance on your security and the safety of information shared with the Service. You can review our privacy policy, data anonymity and erasure procedures and related information .You can review the most current version of the Terms of Service at any time at
                </p>
                    <h3>General Terms and Conditions</h3>
                    <p>You agree that use of the Service is at your sole risk. The Service is provided as an “as is” and “as available” basis. You agree that use of the Service, including any applets, software, content and services accessible therein, is entirely at your own risk.
                    Palomino Consulting does not warrant that the service is free from bugs, viruses, errors, or other program limitations, nor does Palomino Consulting warrant access to the Internet or to any other service or content through the Service.
                    The entire liability of Palomino Consulting and its Representatives (as defined below) for any reason shall be limited to the amount paid by the customer for the related services or content purchased from Palomino Consulting, its authorized reseller or its service providers.
                    To the maximum extent permitted by applicable law, Palomino Consulting and its subsidiaries, affiliates, licensors, participating financial institutes, third-party content or service providers, distributors, dealers or suppliers (“Representatives”) are not liable for any indirect, special, incidental, or consequential damages (including, but not limited to, damages for loss of business, loss of profits or investment, or the like), whether based on breach of warranty, tort (including negligence), product liability or otherwise, even if Palomino Consulting or its representatives have been advised of the possibility of such damages, and even if a remedy set forth herein is found to have failed of its essential purpose.
                    Palomino Consulting's total liability related to or arising out of this agreement shall be limited to the amount of fees actually paid by you to Palomino Consulting in the 12 months prior to the event causing any such liability.
                    Some states do not allow the limitation and/or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
                    The limitations of damages set forth above are fundamental elements of the basis of the bargain between Palomino Consulting and you. Palomino Consulting would not be able to have provided this Service to you without such limitations.
                    You agree that Palomino Consulting has the right to collect, store, and use non-sensitive data that can be uniquely identified to a particular account and otherwise sensitive data that will be anonymized and aggregated.
                    You agree that Palomino Consulting may use your name and logo to identify you as a Palomino Consulting customer on its website or in other sales or marketing materials (provided that Palomino Consulting will not issue any press release regarding your use of the Service without your prior consent).
                   </p>
                    <h3>Copyright, Content and Ownership</h3>
                    <p>As between us, all right, title and interest in and to the Service, any underlying software and any other deliverables, including all derivative works, plug-ins, modifications and copies, and intellectual property rights therein are and shall remain the sole and exclusive property of Palomino Consulting.
                    No title to or ownership of any such items, or any intellectual property or proprietary rights in or to such items, is transferred to you under these Terms of Service or through your use of the Service. Such items are confidential and proprietary to Palomino Consulting and you shall not disclose or provide access to such items to any third party without the prior written consent of Palomino Consulting.
                    </p>
                        <h4>You agree you shall not to:</h4>
                    <p>license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service in any way;
                    modify or make derivative works based upon the Service; or
                    reverse engineer or access the Service in order todevelop, or benchmark against, a competitive product or service, or
                    develop products using, or otherwise copy, features, functions, interfaces or graphics of the Service.
                    Palomino Consulting is the owner and/or authorized user of any trademark, registered trademark and/or service mark appearing in the Service, and is the copyright owner or licensee of the content and/or information in the Service including but not limited to any screens appearing therein.
                    You may not download and/or save a copy of any screens except as otherwise provided in these Terms of Service, for any purpose. However, you may print a copy of the information in the Service for your personal use or records.
                    If you make other use of the Service, except as otherwise provided above, you may violate copyright and other laws of the United States and other countries, as well as applicable state laws, and may be subject to penalties.
                    Palomino Consulting does not grant any license or other authorization to any user of its trademarks, registered trademarks, service marks, or other Copyrightable material or other intellectual property, by placing them in the Service.
                    Palomino Consulting reserves the right, without prior notice, to change any information, features or services contained in the Service.
                    Clicking on certain links within the Service might take you to other websites for which Palomino Consulting assumes no responsibility of any kind (See "Third-Party Provided Links, Content and Service" below).
                    The content presented at in the Service may vary depending on your browser limitations.
                    </p>
                        <h3>Customization & Development</h3>
                    <p>From time to time, you may desire or require additional development and customization of one of our products. On a case-by-case basis, we will review the specifications of these customizations. Where applicable, and at our sole discretion, we may provide the requested development free of charge or for a fee, communicated to and accepted by you. In either case, Palomino Consulting retains the entire right, title, and interest in anything created or developed by us for you, including all patents, copyrights, trade secrets and other proprietary rights. At no time do any rights transfer to you, your agents, or your customers.
                    </p>
                    <h3>User Conduct</h3>
                    <p>While using the Service, you may not:
                    Restrict or inhibit any other user from using and enjoying the Service; or
                    Post or transmit any unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive, or otherwise objectionable information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national or foreign law, including without limitation the U.S. export control laws and regulations; or
                    Post or transmit any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities or schemes or other unsolicited commercial communication (except as otherwise expressly permitted by Palomino Consulting) or engage in spamming or flooding; or
                    Post or transmit any information or software which contains a virus, trojan horse, worm or other harmful component; or
                    Post, publish, transmit, reproduce, distribute or in any way exploit any information, software or other material obtained through the Service for commercial purposes (other than as expressly permitted by the provider of such information, software or other material); or
                    Post, publish, transmit, reproduce, or distribute in any way, information, software or other material obtained through the Service which is protected by copyright, or other proprietary right, or derivative works with respect thereto, without obtaining permission of the copyright owner or right holder; or
                    Upload, post, publish, reproduce, transmit or distribute in any way any component of the Service itself or derivative works with respect thereto, as the Service is protected as a collective work under U.S. copyright laws.
                    Encourage conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national or foreign law, including without limitation the U.S. export control laws and regulations.
                    </p>
                        <h3>Third Party Links, Content and Service</h3>
                    <p>You acknowledge and agree that:
                    Neither Palomino Consulting nor any of its content or service providers shall be liable for any errors or delays in the content, or for any actions taken in reliance thereon.
                    Palomino Consulting has no responsibility for the accuracy or availability of information or services provided by third party linked sites.
                    Links to such sites do not constitute an endorsement by Palomino Consulting of such sites or the content, services, products, advertising or other materials presented on such sites. Information in the many web pages that are linked to the Service comes from a variety of sources.
                    Some of this information comes from official Palomino Consulting licensees, but some may come from unofficial or unaffiliated organizations and individuals, both internal and external to Palomino Consulting.
                    Palomino Consulting does not author, edit, or monitor these unofficial pages or links.
                    </p>
                    <p>You acknowledge and agree that Palomino Consulting shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, services, products advertising or other materials available on such sites.
                    </p>
                        <h3>Payment, Banking, Billing or Other Online Services</h3>
                    <p>If you wish to make purchases in the Service, you may be asked by the merchant or information or service provider from whom you are making the purchase to supply certain information, including credit card or other payment mechanism information. You agree:
                    Palomino Consulting is not liable for any loss or damage of any sort incurred as a result of any such dealings with any merchant or information or service provider through the Service.
                    That all information you provide any merchant or information or service provider through the Service for purposes of making purchases will be accurate, complete and current.
                    The merchants and information and service providers offering merchandise, information and services through the Service set their own prices and may change prices or institute new prices at any time.
                    To pay all charges incurred by users of your account and credit card or other payment mechanism at the prices in effect when such charges are incurred.
                    If you signed up for a monthly plan, you will be charged at the beginning of each month. Annual plans may incur monthly charges for monthly services, including but not limited to Palomino Consulting Insurance.
                    By signing up for a subscription service with Palomino Consulting, you authorize the company to charge your credit card for your plan total on an annual or monthly recurring basis, until canceled.
                    You will be responsible for paying any applicable taxes relating to purchases through the Service. For businesses based in the United States, tax will be calculated and collected by Palomino Consulting based on your billing zip code.
                    You agree Palomino Consulting is not liable for any loss or damage of any sort incurred as a result of any such dealings with any services provided by your chosen third-party.
                    Your access may be limited from time to time, depending on the service provided by your Internet service provider or your chose third-party services.
                    You may be billed for these Online Services by your chose third-party, not
                    Palomino Consulting, and such financial institution may have its own service agreement which will govern the Online Services it provides.
                    You agree to be responsible for all telephone charges associated with your Internet and online service usage.
                    </p>
                       <h3> Miscellaneous</h3>
                <p>
                The Terms of Service and the relationship between you and Palomino Consulting shall be governed by the laws of the State of ​Nevada without regard to its conflict of law provisions. You and Palomino Consulting agree to submit to the personal and exclusive jurisdiction of the courts located within the county of ​Clark, State of Nevada.
                    You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the Terms of Service must be filed within one (1) year after such claim or cause of action arose or be forever barred.
                    By accessing and using this site and Palomino consulting apps you agree to the Terms of Service.</p>
            </div>
        </Container>
    )
}
export default Terms;
