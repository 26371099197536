const timeZones = ['US/Pacific','US/Mountain','US/Central','US/Eastern','US/Alaska','US/Hawaii']

const integrationNames = ['DUTCHIE', 'FLOWHUB', 'CANIX']

const defaultDebitTypes = {'debitPaid': 'Debit Clearing Account',
                            'checkPaid': 'Check Clearing Account',
                            'creditPaid': 'Credit Clearing Account',
                            'prePaymentAmount': 'Dutchie Pay Clearing Account',
                            'debit': 'Debit Clearing Account',
                            'giftcard': 'Debit Clearing Account'}

const inventoryCategoryStatus = 'inventoryCategoryStatus'
const userSettings = {'': {integration_name: undefined}}

const accountingBasis = ['Accrual', 'Cash']

const settingsNames = ['parent_acc_name', 'pos_timezone', 'accounting_basis', 'dutchie_key',
    'vault_acc_name', 'sales_customer_name', 'cash_var_acc_name', 'debit_tracking_class',
    'inventory_acc_name', 'inventory_acc_type', 'inventory_sales_term_standard',
    'inventory_tracking_class', 'inventory_vendor_naming_map', 'inventory_cash_basis_acc_name',
    'income_tracking_class_map', 'income_breakdown', 'income_cogs_breakdown', 'income_discounts_breakdown',
    'income_merge_discounts', 'income_tax_naming_map', 'income_sales_line_name',
    'income_single_register', 'debit_single_register']

const settingsNamesEssential = ['parent_acc_name', 'pos_timezone', 'dutchie_key']

const reportTypes = [
    { value: 'TRANSACTION', label: 'Vault Transfers'},
    { value: 'INVENTORY', label: 'Bills'},
    { value: 'INCOME', label: 'Sales & Refunds'},
    { value: 'DEBIT', label: 'Debits'},
    { value: 'OVERSHORT', label: 'OverShorts'},
    { value: 'OVERSHORT_LITE', label: 'OverShorts'},
    { value: 'MIMO_CASHDROP', label: 'Mimo Cashdrop'},
    { value: 'MIMO_VENDOR_PAYOUT', label: 'Mimo Vendor Payouts'}
]

const getUrl = (endpointName) => {
    return 'https://' + window.location.hostname + '/' + endpointName
}
export {userSettings, integrationNames, timeZones, getUrl, inventoryCategoryStatus, accountingBasis, defaultDebitTypes, settingsNames, settingsNamesEssential, reportTypes}