import {
    Container, Button, Col, Row
} from 'reactstrap';

import React from 'react';

const Home = () => {
    return (
        <Container className="mainContainer" fluid>
        <div className="imageHolder">
            <img className="homeImage" alt="horse" src="/horse.png"/>
            <h1 className="homeHeadText"> Financial Intelligence&nbsp;for <br></br> Retail ​Businesses</h1>
        </div>
            <Row xl="2" lg="1" md="1" sm="1" xs="1">
                <Col className="holderCol">
                    <div className="homeTextBlock">
                        <h2> Our Journey</h2>
                        <p > Racetrack was born as we navigated the unique challenges of the cannabis
                            space through accounting service agreements with multiple dispensaries. It
                            quickly became evident that a specialized middleware solution was essential
                            to maintain visibility at scale. </p>
                        <Button size="lg" className="sumbmitBtnHome" onClick={() => window.location = 'mailto:sean@palomino.consulting'}>Contact us</Button>
                    </div>
                </Col>
                <Col className="holderCol">
                    <div className="homeTextBlock">
                        <h2> Our Solution</h2>
                        <p > Racetrack is a private, custom data transformation tool specifically designed
                            for QuickBooks Online (QBO) and various POS systems. It seamlessly bridges
                            the gap between raw data and actionable insights, tailored to meet the
                            unique needs of dispensaries. </p>
                        <br></br>
                        <Button color="link" className="linkBtn" href="/dutchie">Dutchie Solution</Button>
                        <Button color="link" className="linkBtn" href="/flowhub">Flowhub Solution</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;