import {userSettings} from "../docs/data";

export function getUserSettings() {
    const user_settings = sessionStorage.getItem('user_settings');
    if (!user_settings) {
        return userSettings;
    } else {
        return JSON.parse(user_settings);
    }
}

function getAccountSettings(qboAccount, settingName) {
    const userSettings = getUserSettings()
    if (userSettings[qboAccount] && userSettings[qboAccount][settingName]) {
        let setting = userSettings[qboAccount][settingName]
        return  typeof(setting) === 'object' ? JSON.stringify(setting) : setting
    }
    if (settingName === 'income_tracking_class_map'){
        return '{"ALL_TX_TYPES":""}'
    }
    if (settingName === 'inventory_vendor_naming_map' || settingName === 'income_tax_naming_map') {
        return '{}'
    }
    return ''
}


function getHeader() {
    return {headers: {'csrf-token': sessionStorage.getItem('token'),
                        'username': sessionStorage.getItem('username')}}
}

function getSessionItem(item_name) {
    console.log('Getting session item ', item_name)
   let item = sessionStorage.getItem(item_name)
   if (item === 'undefined' || !item) {
        return '';
    } else {
        return item;
    }
}

function setUserSession(user_settings, header) {
    console.log('Setting session')
    if (user_settings) sessionStorage.setItem('user_settings', JSON.stringify(user_settings));
    sessionStorage.setItem('token', header['csrf-token']);
    sessionStorage.setItem('username', header['username']);
    sessionStorage.setItem('subscription_type', header['subscription-type']);
}

function setSetting(name, value) {
    console.log('Setting item: ', name, value)
    sessionStorage.setItem(name, value);
}

function makeAuthCall() {
    console.log('Making auth call');
}

const logOutHandler = (login, setLogin) => {
    if (login) {
        console.log('Resetting session')
        sessionStorage.clear();
        setLogin(false)
    }
}

export {getAccountSettings, setSetting, setUserSession, getSessionItem, getHeader, logOutHandler, makeAuthCall};