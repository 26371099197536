import {
    Container, Button, Col, Row
} from 'reactstrap';

import React from 'react';

const RacetrackFlowub = () => {
    return (
        <Container className="mainContainer" fluid>
        <div className="imageHolder">
            <img className="homeImage" alt="horse" src="/horse.png"/>
            <h1 className="homeHeadText"> POS System Integration <br></br> Flowhub + QuickBooks Online</h1>
        </div>
            <Row xl="2" lg="1" md="1" sm="1" xs="1">
                <Col className="holderCol">
                    <div className="homeTextBlock">
                        <h2> About the App</h2>
                        <p > Racetrack is a private, custom data transformation tool specifically designed
                            for QuickBooks Online (QBO) and Flowhub POS system. It seamlessly bridges
                            the gap between raw data and actionable insights, tailored to meet the
                            unique needs of dispensaries.<br></br>
                        </p>
                        <p >By subscribing to this app, the
                            Quickbooks account will be populated daily with the data specified above.&nbsp;<br></br>
                        </p>

                        <h2> App Features</h2>
                        <ul >
                            <li>Creates Sales Receipts and Refund Receipts based on POS data</li>
                            <li>Breaks down sales by product category</li>
                            <li>Sales/returns, COGS, discounts, taxes, and fees are created as individual services</li>
                            <li>Provides custom account mapping using QBO products/services infrastructure</li>
                            <li>Creates a journal entry for the debit transactions performed on the registers</li>
                            <li>Back-populates sales and debit transfers for the desired period</li>
                        </ul>

                        <Button size="lg" className="sumbmitBtnHome" onClick={() => window.location = 'mailto:sean@palomino.consulting'}>Contact us</Button>
                    </div>
                </Col>
                <Col className="holderCol" >
                    <div className="videoHolder">
                        <iframe
                            width="853"
                            height="480"
                            src="https://www.youtube.com/embed/gYq2-LacIo8?si=Rmri2aXXJO7xWvgl"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Col>
                </Row>
        </Container>
    )
}

export default RacetrackFlowub;