import React from 'react';

const Contact = () => {
    return (
        <div className="holderDiv">
            <div className="baseTextBlock">
                <h2>Contact Us</h2>
                <br></br>
                <p className="paragraph"> 8867 W Flamingo Rd. Suite 201
                    <br></br>
                    Las Vegas, NV 89147
                    <br></br>
                    sean@palomino.consulting
                </p>
            </div>
        </div>
    )
}
export default Contact;
