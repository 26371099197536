import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Row
} from 'reactstrap';
import axios from 'axios';
import Cookies from 'js-cookie';

import {makeAuthCall, makeDeauthCall, makeQboCheck} from './service/qboAuthService';
import {AccountsSelector, BasicSelector} from './components/select';
import {getHeader, setUserSession} from './service/sessionStorage';
import {handleError} from "./service/apiErrorHandler";
import BugSubmissionModal from "./components/modal";
import {TransactionSettings} from "./components/reports/TransactionSettings";
import {ReportManagementGroup} from "./components/reportManagmeent";
import {getUrl, integrationNames, inventoryCategoryStatus} from "./docs/data";
import {IncomeSettings} from "./components/reports/IncomeSettings";
import {DebitSettings} from "./components/reports/DebitSettings";
import {InventorySettings} from "./components/reports/InventorySettings";
import {OvershortSettings} from "./components/reports/OvershortSettings";
import {GlobalSettings} from "./components/reports/GlobalSettings";


const Settings = (props) => {
    const [qboAccount, selectAccount] = useState(undefined)
    const [previousCallAccount, setPreviousCallAccount] = useState({qboAccount: undefined, status: true})
    const [accountNames, setAccountNames] = useState(undefined)
    const [state, setState] = useState('')

    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [alertColor, setColor] = useState('')

    const [messageQBO, setMessageQBO] = useState('')
    const [visibleQBO, setVisibleQBO] = useState(false)

    useEffect(() => {
        let qboConnStatus = makeQboCheck(setMessageQBO, setColor, setVisibleQBO)
    }, [])

    useEffect(() => {
        if (qboAccount && previousCallAccount.name !== qboAccount && previousCallAccount.status) {
            console.log('Requesting account names')
            let header = getHeader()
            const url = getUrl('account_name')
            setPreviousCallAccount({name: qboAccount, status: false})  // lock that prevents from second call while the previous creds are not set
            axios.get(url, header)
                .then(function (response) {
                    if (response.status === 200) {
                        setUserSession(undefined, response.headers)
                        setAccountNames(response.data)
                        setPreviousCallAccount({name: qboAccount, status: true})
                    }
                }).catch(error => handleError(error, setMessage, setVisible, setColor))
        }
    }, [qboAccount, previousCallAccount.status])

    const onQBOMessageDismiss = () => {
        setVisibleQBO(false);
        Cookies.set('qbo-con-status', null);
    }
    const refreshSettings = () => {
        console.log('Refreshing settings.')

        const url = getUrl('verify')
        let header = getHeader()
        axios.get(url, header)
            .then(function (response) {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    window.location.reload();
                }
            }).catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    const saveSettings = (event) => {
        if (!previousCallAccount.status) return
        setPreviousCallAccount({qboAccount: qboAccount, status: false})

        event.preventDefault();
        const url = getUrl('save_settings')
        if (!qboAccount) {
            setMessage('QBO Account required.');
            setVisible(true);
            setColor('warning')
            return
        }

        if (!state.integration_name === 'FLOWHUB' && !state.pos_timezone) {
            setMessage('Time Zone field is required.');
            setVisible(true);
            setColor('warning')
            return
        }

        if (!state.integration_name) {
            setMessage('Integration name is required.');
            setVisible(true);
            setColor('warning')
            return
        }

        if (state.dutchie_key && state.dutchie_key.length !== 32) {
            setMessage('Check Dutchie key: should be 32 characters long');
            setVisible(true);
            setColor('warning')
            return
        }

        if (state.canix_token && state.canix_token.length !== 64) {
            setMessage('Check Canix token: should be 64 characters long');
            setVisible(true);
            setColor('warning')
            return
        }

        if ( (state.flow_token && state.flow_token.length !== 36) || (state.flow_client_id && state.flow_client_id.length !== 36) ) {
            setMessage('Check Flow token and ClientId: should be 34 characters long');
            setVisible(true);
            setColor('warning')
            return
        }

        let namingMaps = ['inventory_vendor_naming_map', 'income_tax_naming_map', 'income_tracking_class_map', 'canix_facility_type_map']
        for (let item of namingMaps) {
            if (typeof(state[item]) === 'string') {
                try {
                    state[item] = JSON.parse(state[item])
                }
                catch (SyntaxError) {
                    setMessage('Check ' + item + ' formatting');
                    setVisible(true);
                    setColor('warning')
                    return
                }
            }
        }

        // putting acc name to the cash basis param if there is input from user
        if (state.accounting_basis === 'Cash' && state.inventory_acc_name) {
            state.inventory_cash_basis_acc_name = state.inventory_acc_name
        }

        if (!state.inventoryCategoryStatus) {
            state.inventory_category_map = {}
        }

        let requestBody = {};
        requestBody['settings'] = state
        requestBody['qbo_acc_name'] = qboAccount
        let header = getHeader()

        console.log('Saving settings with body ', requestBody)
        axios.post(url, requestBody, header)
            .then(response => {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    setPreviousCallAccount({qboAccount: qboAccount, status: true})

                    setMessage('Settings saved successfully');
                    setVisible(true);
                    setColor('primary')
                }
            })
            .catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    const qboAccNames = () => {
        return accountNames ? accountNames[qboAccount] : accountNames
    }

    return (
        <Container className="border-light" fluid>
            <div className="Main">
                <br></br>
                <h5>Settings</h5>
                <br></br>

                <Row>
                    <Col xs="7">
                        <AccountsSelector setState={setState} selectAccount={selectAccount} />
                    </Col>
                    <Col>
                        <ButtonGroup>
                            <Button value="Refresh settings" onClick={refreshSettings}> Refresh settings </Button>
                            <Button color="success" value="Connect QBO" onClick={() => makeAuthCall(setVisibleQBO, setMessageQBO, setColor)}> Connect to QBO </Button>
                            <Button color="danger" value="Disconnect QBO" onClick={() => makeDeauthCall(qboAccount, setVisibleQBO, setMessageQBO, setColor)}> Disconnect from QBO </Button>
                            {/*<Button color="danger" value="SHOW QBO" onClick={() => console.log('STATE', state)}> Show State </Button>*/}
                        </ButtonGroup>
                    </Col>
                    <BasicSelector formName='Integration Name'
                                   name='integration_name'
                                   componentName='IntegrationSelector'
                                   items={integrationNames}
                                   state={state}
                                   setState={setState}
                                   tooltipContent="Pick the POS software you're using"/>
                </Row>
                <Alert color={alertColor} isOpen={visibleQBO} toggle={onQBOMessageDismiss} className="message">{messageQBO}</Alert>
                <Form id="mainForm" className="form" onSubmit={saveSettings}>
                    <GlobalSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <TransactionSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <IncomeSettings state={state} setState={setState}/>
                    <DebitSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <InventorySettings state={state} setState={setState} qboAccount={qboAccount} qboAccNames={qboAccNames()}/>
                    <OvershortSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>

                    {message && <Alert color={alertColor} isOpen={visible} toggle={() => setVisible(false)} className="message">{message}</Alert>}
                    <Button type="submit" value="Save" color="success" >
                        Save settings
                    </Button>
                    <BugSubmissionModal qboAccount={qboAccount}/>
                    <br></br>
                </Form>
                <ReportManagementGroup state={state} setState={setState} qboAccount={qboAccount}/>
            </div>
        </Container>
    )
}

export default Settings;