import {FormField} from "../input";
import {DebitSettingsGroup, SettingsGroup} from "../settingsGroup";
import React from "react";
import {DebitSelect} from "../singleSelect";

export const FlowhubDebitSettings = ({state, setState, qboAccNames}) => {
    return (
        <SettingsGroup groupName={<div id='DEBIT'>Debit Payments</div>}
                       reportType='DEBIT'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable module. Creates journal entries that hold non-cash payments summary. Payment methods: default, checkPaid, creditPaid. Granularity: by register, day, payment method.'>
            <FormField groupName='Debit Tracking Class'
                       formName='Tracking class for debit transactions'
                       placeholder='Default value: Disabled'
                       state={state}
                       setState={setState}
                       name='debit_tracking_class'/>
            <DebitSettingsGroup groupName='Default Debit Payment Method'
                                name='debit_payment_types'
                                reportType='debit'
                                state={state}
                                setState={setState}>
                <DebitSelect groupName='Basic Debit Payment Method'
                             formName='Clearing account name'
                             name='debit'
                             state={state}
                             setState={setState}
                             options={qboAccNames}/>
            </DebitSettingsGroup>
            <DebitSettingsGroup groupName='Additional Debit Payment Method (giftcard)'
                                name='debit_payment_types'
                                reportType='giftcard'
                                state={state}
                                setState={setState}>
                <DebitSelect groupName='Additional Debit Payments Method (giftcard)'
                             formName='Clearing account name'
                             name='giftcard'
                             state={state}
                             setState={setState}
                             options={qboAccNames}/>
            </DebitSettingsGroup>
        </SettingsGroup>
    )
}