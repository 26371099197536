import React from "react";
import JsonToTable from "./table";
import {Button, UncontrolledCollapse} from "reactstrap";
import {reportTypes} from "../docs/data";


function RunReportDetails(props) {
    const runLock = JSON.parse(props.state['run_lock'])
    const reportStr = runLock['lambda_name'] === 'remove_reports' ? props.state['remove_run_result'] : props.state['historical_run_result']
    const report = reportStr ? JSON.parse(reportStr) : ''
    if (Object.keys(report).length === 0) return

        const getReportNameMap = () => {
        let map = {}
        for (let obj of reportTypes) {
            map[obj.value] = obj.label
        }
        return map
    }
    const reportNameMap = getReportNameMap()
    const parseReports = () => {
        let result = []
        for (const date in report) {
            let row = {'Report Date': date}
            for (const reportName in report[date]) {
                if (reportName !== 'FAILS') {
                    let runReport = report[date][reportName]
                    let fails = runReport ? runReport.reduce((acc, x) => acc + x.failed.length, 0) : 0

                    let prettyReportName = reportNameMap[reportName]
                    row[prettyReportName] = fails > 0 ? 'failed' : 'succeeded'
                }
            }
            result.push(row)
        }
        return result
    }
    let parsedData = parseReports();
    let headers = Object.keys(parsedData[0])


    return (
        <div>
            <Button color="success" id="toggler" style={{ marginBottom: '1rem' }}>
                Last Run Statistics
            </Button>
            <UncontrolledCollapse toggler="#toggler">
                <h5>{runLock['lambda_name'] === 'remove_reports' ? 'Remove ' : 'Historical '}Run</h5>
                <JsonToTable theadData={headers} tbodyData={parsedData}/>
            </UncontrolledCollapse>
        </div>
    )
}

export default RunReportDetails;
