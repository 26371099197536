import React, {useEffect, useState} from 'react';
import {BrowserRouter, NavLink as RRNavLink, Route, Switch} from "react-router-dom";
import {
  Collapse,
  Navbar, NavbarToggler, NavbarBrand,
  Nav, NavItem,NavLink
} from 'reactstrap';
import PrivateRoute from './routes/privateRoute';

import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import Settings from "./Settings";
import Contact from "./Contact";
import Privacy from "./Privacy";
import Terms from "./Terms";
import {logOutHandler} from "./service/sessionStorage";
import RacetrackDutchie from "./RacetrackDutchie";
import RacetrackFlowhub from "./RacetrackFlowhub";
import {EmailStatus} from "./service/EmailStatus";

function App() {
    const [collapsed, setCollapsed] = useState(true);
    const [login, setLogin] = useState(false);
    useEffect(() => {
        sessionStorage.getItem('token') ? setLogin(true) : setLogin(false)
    }, [window.location.pathname]);

    const toggleNavbar = () => setCollapsed(!collapsed);
    // const resolvePrivatePage = getSessionItem('subscription_type') === 'advanced' ? Settings : SettingsEssential


    return (
    <div className="App">
      <BrowserRouter>
        <Navbar className="navBar" dark expand="ml-auto">
            <NavbarBrand href="/">
                <img className='logoBig' alt="logo" src="/logo.png" style={{height: 77, width: 339}}/>
                <img className='logoSmall' alt="logo" src="/logo-small.png" style={{height: 50, width: 50}}/>
            </NavbarBrand>

            <NavbarToggler onClick={toggleNavbar} className="me-2 navbar-toggler"/>
            <Collapse isOpen={!collapsed} navbar>
            <Nav className="me-2" navbar>
                <NavItem>
                  <NavLink tag={RRNavLink}
                           activeCalssName="active"
                           to="/login-page"
                           onClick={() => logOutHandler(login, setLogin)}>
                      {login ? 'Log out': 'Log in'}
                  </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RRNavLink}
                             activeCalssName="active"
                             to="/contact-page">
                        Contact
                    </NavLink>
                </NavItem>
            </Nav>
        </Collapse>
        </Navbar>
        <div className="content">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/dutchie" component={RacetrackDutchie}/>
            <Route exact path="/flowhub" component={RacetrackFlowhub}/>
            <Route path="/register-page" component={Register}/>
            <Route path="/login-page" component={Login}/>
            <Route path="/contact-page" component={Contact}/>
            <Route path="/terms-page" component={Terms}/>
            <Route path="/email-status" component={EmailStatus}/>
            <Route path="/privacy-page" component={Privacy}/>
            <PrivateRoute path="/settings-page" setLogin={setLogin} component={{advanced: Settings}}/>
              <Route path='/inventory-explorer' component={() => {
                  window.location.href = 'https://palominoconsulting.retool.com/embedded/public/c6558cf5-12a0-4360-bb73-bb20fba899c7';
                  return null;
              }} />
          </Switch>
        </div>
          <Nav className="navFooter">
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/dutchie">Dutchie Integration</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/flowhub">FlowHub Integration</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/terms-page">Terms & Conditions</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/privacy-page">Privacy Policy</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink" tag={RRNavLink} activeCalssName="active" to="/register-page">Register</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink" tag={RRNavLink} activeCalssName="active" to={login ? "/settings-page": "/login-page"} >{login ? 'Settings': 'Log in'}</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/contact-page">Contact</NavLink>
              </NavItem>
          </Nav>
      </BrowserRouter>
    </div>
  );
}

export default App;
