import React, {useEffect, useState} from 'react';
import {Col, Button, FormGroup, Input, Label, Row, UncontrolledTooltip} from 'reactstrap';
import CreatableSelect from "react-select/creatable";
import {defaultDebitTypes, inventoryCategoryStatus} from "../docs/data";


export function SingleSelect(props) {
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState({})

    useEffect(() => {
        // Option1 -> async resp + we have a new selection -> put it as is
        // Option2 -> no async resp with account + db state as is -> put just as is
        // Option3.1 -> async resp present + db state is default string -> search for corresponding value in resp
        // Option4 -> async resp present + db state is default number -> search for corresponding label in resp
        // Option5 -> async resp present + db state is default string -> search for corresponding value/label in resp -> not created (saved) yet -> put as is
        let selectedOption = props.state[props.name]
        if (!props.options) {
                selectedOption = {value: selectedOption, label: selectedOption}
        }

        if (props.options) {
            setOptions(props.options)
            if (typeof(selectedOption) === 'string') {
                selectedOption = props.options.filter(x => x.label === selectedOption)[0]
            }
            if (typeof(selectedOption) === 'number') {
                selectedOption = props.options.filter(x => x.value === selectedOption)[0]
            }
            if (!selectedOption) selectedOption = {value: props.state[props.name], label: props.state[props.name]}
        }
        setSelected(selectedOption)
    }, [props.state, props.options])

    const handleInput = (event) => {
        props.state[props.name] = event === null ? event : event.value
        props.setState({...props.state})
    }

    return (
        <FormGroup>
            <Label for={props.groupName} id={props.name}>
                {props.formName}
            </Label>
            <CreatableSelect value={selected}
                             className="basic-multi-select"
                             classNamePrefix="select"
                             name="accountSelect"
                             isClearable
                             options={options}
                             onChange={handleInput}
            />
        </FormGroup>
    );
}

export function DebitSelect(props) {
    const settingName = 'debit_payment_types'
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState({})

    useEffect(() => {
        // Option1 -> async resp + we have a new selection -> put it as is
        // Option2 -> no async resp with account + db state as is -> put just as is
        // Option3.1 -> async resp present + db state is default string -> search for corresponding value in resp
        // Option4 -> async resp present + db state is default number -> search for corresponding label in resp
        // Option5 -> async resp present + db state is default string -> search for corresponding value/label in resp -> not created (saved) yet -> put as is
        let optionsState = props.state[settingName] ? props.state[settingName] : defaultDebitTypes

        // for (const [key, value] of Object.entries(defaultDebitTypes)) {
        //     if (!optionsState[key]) optionsState[key] = value
        // }

        let selectedOption = optionsState[props.name]
        if (!props.options) {
            selectedOption = {value: selectedOption, label: selectedOption}
        }

        if (props.options) {
            setOptions(props.options)
            if (typeof(selectedOption) === 'string') {
                selectedOption = props.options.filter(x => x.label === selectedOption)[0]
            }
            if (typeof(selectedOption) === 'number') {
                selectedOption = props.options.filter(x => x.value === selectedOption)[0]
            }
            if (!selectedOption) selectedOption = {value: optionsState[props.name], label: optionsState[props.name]}
        }
        setSelected(selectedOption)
    }, [props.state, props.options])

    const handleInput = (event) => {
        let optionsState = props.state[settingName] ? props.state[settingName] : defaultDebitTypes
        optionsState[props.name] = event === null ? defaultDebitTypes[props.name] : event.value
        props.setState({...props.state})
    }

    return (
        <FormGroup>
            <Label for={props.groupName} id={props.name} targetId={props.name}>
                {props.formName}
            </Label>
            <CreatableSelect value={selected}
                             className="basic-multi-select"
                             classNamePrefix="select"
                             name="accountSelect"
                             isClearable
                             options={options}
                             onChange={handleInput}
            />
            <UncontrolledTooltip placement="top" target={props.name}>
                {props.tooltipContent}
            </UncontrolledTooltip>
        </FormGroup>
    );
}

export function InventoryCategorySelect(props) {
    const settingName = props.settingName
    const [options, setOptions] = useState([{ catName: "", catValue: {value: '', label: ''} }])

    // we convert to [{ catName: "", catValue: {value: selectedOption, label: selectedOption}}]

    const resolveOption = (key, value) => {
        if (!props.options && typeof(value) === 'string') {
            return {value: value, label: value}
        }
        if (!props.options) {
            return {value: key, label: key}
        }

        if (props.options) {
            if (typeof(value) === 'string') {
                return props.options.filter(x => x.label === value)[0]
            }
            if (typeof(value) === 'number') {
                return props.options.filter(x => x.value === value)[0]
            }
            if (!value) return {value: key, label: key}
        }
    }

    useEffect(() => {
        // Option1 -> async resp + we have a new selection -> put it as is
        // Option2 -> no async resp with account + db state as is -> put just as is
        // Option3.1 -> async resp present + db state is default string -> search for corresponding value in resp
        // Option4 -> async resp present + db state is default number -> search for corresponding label in resp
        // Option5 -> async resp present + db state is default string -> search for corresponding value/label in resp -> not created (saved) yet -> put as is
        let optionsState = props.state[settingName]
        if (optionsState) {
            let mappedOptions = []
            for (const [key, value] of Object.entries(optionsState)) {
                let opt = { catName: key, catValue: resolveOption(key, value) }
                mappedOptions.push(opt)
            }
            setOptions([...mappedOptions])
        }

    },  [props.state, props.options])

    // useEffect(() => {
    //     // getting defaults back after toggling settings group on/off
    //     if (props.state[inventoryCategoryStatus] && !props.state[settingName][props.name]) {
    //         props.state[settingName][props.name] = fhCategories[props.name]
    //         props.setState({...props.state})
    //     }
    // }, [props.state[inventoryCategoryStatus]])

    const handleCatNameChange = (event, idx) => {
        const optName = event.target.name
        const value = event.target.value

        props.state[settingName][value] = props.state[settingName][optName]
        delete props.state[settingName][optName]

        props.setState({...props.state})
    }

    const handleCatValueChange = (event, optName) => {
        const oldValue = props.state[settingName][optName]
        const newValue = event === null ? oldValue : event.value
        props.state[settingName][optName] = newValue
        props.setState({...props.state})
    }

    const addRow = () => {
        props.state[settingName][''] = ''
        props.setState({...props.state})
    };

    const deleteRow = (idx) => {
        let targetOptionKey = options[idx]['catName']
        delete props.state[settingName][targetOptionKey]
        props.setState({...props.state})
    }

    return (
        <FormGroup>
            {options.map((opt, index) => (
            <Row xs="4">
                <Col>
                    <Input type='text'
                           placeholder={''}
                           name={opt.catName}
                           value={opt.catName}
                           onChange={(e) => handleCatNameChange(e, index)}/>
                </Col>
                <Col xs="6">
                    <CreatableSelect value={opt.catValue}
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     name={opt.catName}
                                     isClearable
                                     options={props.options}
                                         onChange={(e) => handleCatValueChange(e, opt.catName)}/>
                </Col>
                <Button value="Delete Row" onClick={() => deleteRow(index)}>Delete</Button>
            </Row>
                ))}
            <br></br>
            <Button value="Add Row" onClick={addRow}>Add Category</Button>
        </FormGroup>
    );
}