import React, {useState} from 'react';
import {
    Form, FormGroup,
    Label, Input,
    Button, Alert,
    Container, UncontrolledTooltip,
} from 'reactstrap';
import axios from 'axios';
import {handleError} from "./service/apiErrorHandler";
import {Toggle} from "./components/toggle";

const registerUrl = 'https://' + window.location.hostname + '/register'

const Register = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');

    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(true);
    const [alertColor, setColor] = useState('');
    console.log(registerUrl)

    const onDismiss = () => setVisible(false);

    const submitHandler = (event) => {
        event.preventDefault();

        if (email.trim() === '' ||
            password.trim() === '' || confirm_password.trim() === '') {
            setMessage('All fields required.');
            setVisible(true);
            setColor('warning')
            return
        } else if (confirm_password.trim() !== password.trim()) {
            setMessage('Passwords do not match.');
            setVisible(true);
            setColor('warning')
            return
        }

        const requestBody = {
            username: email.trim(),
            password: password.trim()
        }

        axios.post(registerUrl, requestBody)
            .then(response => {
                if (response.status === 200) {
                    setMessage("Registration successful. We've sent you validation email (don't hesitate checking your spam folder)");
                    setVisible(true);
                    setColor('primary');
                }
            }).catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    return (
        <Container className="border-light" fluid>
          <div className="Register">
            <h2>Register</h2>
            <Form className="form" onSubmit={submitHandler}>
              <FormGroup>
                <Label for="clientName">
                    User Email
                </Label>
                <Input type="email" placeholder="maryjane@gmail.com" value={email} onChange={event => setEmail(event.target.value)}/>
              </FormGroup>
              <FormGroup>
                <Label for="Password">
                  Password
                </Label>
                <Input type="password" placeholder="password" value={password} onChange={event => setPassword(event.target.value)}/>
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">
                  Confirm password
                </Label>
                <Input type="password" placeholder="confirm password" value={confirm_password} onChange={event => setConfirmPassword(event.target.value)}/>
              </FormGroup>
                {message && <Alert color={alertColor} isOpen={visible} toggle={onDismiss} className="message">{message}</Alert>}
                <Button className="sumbmitBtn" type="submit" value="Register">
                  Register
              </Button>
            </Form>
          </div>
        </Container>
    )
}

export default Register;