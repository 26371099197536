import {
    Container, Button, Col, Row
} from 'reactstrap';

import React from 'react';

const RacetrackDutchie = () => {
    return (
        <Container className="mainContainer" fluid>
        <div className="imageHolder">
            <img className="homeImage" alt="horse" src="/horse.png"/>
            <h1 className="homeHeadText"> POS System Integration <br></br> Dutchie + QuickBooks Online</h1>
        </div>
            <Row xl="2" lg="1" md="1" sm="1" xs="1">
                <Col className="holderCol">
                    <div className="homeTextBlock">
                        <h2> About the App</h2>
                        <p > We provide accounting and financial
                            services to retail businesses. The Retail businesses use a Point of Sale system (POS) to process
                            transactions in the stores. The app uses this data to generate financial reports in QuickBooks
                            Online.<br></br>
                            <br></br>The app supports the following features:<br></br>
                        </p>
                        <ul >
                            <li>Creates QB transfers for all cash drops from registers into a dispensary's vault</li>
                            <li>Creates a summarized QB Bill for any inventory received through Dutchie</li>
                            <li>Summarizes transactions to create a daily report for each register on the Retail side</li>
                            <li>Sales/returns, COGS, discounts, taxes, and fees are created as individual services</li>
                            <li>Over/shorts during register close out in Dutchie are populated as a journal entry</li>
                            <li>Takes any sale or return transaction marked as debit in the Dutchie POS</li>
                        </ul>
                        <p >By subscribing to this app, the
                            Quickbooks account will be populated daily with the data specified above.&nbsp;<br></br>
                        </p>
                        <Button size="lg" className="sumbmitBtnHome" onClick={() => window.location = 'mailto:sean@palomino.consulting'}>Contact us</Button>
                    </div>
                </Col>
                <Col className="holderCol" >
                    <div className="videoHolder">
                        <iframe
                            width="853"
                            height="480"
                            src="https://www.youtube.com/embed/bJWEJ7l1GM8?si=LlikjzKUYa1d1Lic"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Col>
                </Row>
        </Container>
    )
}

export default RacetrackDutchie;