import React, {useState} from 'react';
import {
  Form, FormGroup,
  Label, Input,
  Button, Alert,
  Container
} from 'reactstrap';
import axios from 'axios';
import {setUserSession} from './service/sessionStorage';
import {handleError} from "./service/apiErrorHandler";
import {getUrl} from "./docs/data";

const Login = (props) => {
    const [username, setName] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(true);
    const [alertColor, setColor] = useState('');

    const onDismiss = () => setVisible(false);

    if (sessionStorage.getItem('token')) props.history.push('/settings-page')

    const submitHandler = (event) => {
        event.preventDefault();

        if (username.trim() === '' || password.trim() === '') {
            setMessage('All fields are required.');
            setVisible(true);
            setColor('warning');
            return
        }

        const requestBody = {
            username: username.trim(),
            password: password.trim()
        }
        console.log('Logging into ', username.trim())
        const url = getUrl('login')
        axios.post(url, requestBody)
            .then(function (response) {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    props.history.push('/settings-page');
                }
            }).catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    return (
        <Container className="border-light" fluid>
          <div className="Login">
            <h2>Login</h2>
            {message && <Alert color={alertColor} isOpen={visible} toggle={onDismiss} className="message">{message}</Alert>}
            <Form className="form" onSubmit={submitHandler}>
              <FormGroup>
                <Label for="clientName">
                  Username or Email
                </Label>
                 <Input type="text" placeholder="name of a client" value={username} onChange={event => setName(event.target.value)}/>
              </FormGroup>
              <FormGroup>
                <Label for="Password">
                  Password
                </Label>
                <Input type="password" placeholder="password" value={password} onChange={event => setPassword(event.target.value)}/>
              </FormGroup>
              <Button className="sumbmitBtn" type="submit" value="Login">
                  Login
              </Button>
            </Form>
          </div>
        </Container>
    )
}

export default Login;